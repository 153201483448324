import {
  removeLocalCacheKey,
  resetLocalCacheKey,
  setUrlParamToSessionStorage,
} from '@vivino/js-web-common';
import { setLanguage } from 'vivino-js/api/users';
import { fetchAndUpdateCartsContext } from 'vivino-js/context/CartsContext';

window.removeLocalCacheKey = removeLocalCacheKey;
window.resetLocalCacheKey = resetLocalCacheKey;
window.setLanguage = setLanguage;

// This fetches the carts once and feeds them to the carts provider
// which may be instantiated several times
fetchAndUpdateCartsContext();

// Adds the utm_medium param in window's sessionStorage
setUrlParamToSessionStorage('utm_medium');
